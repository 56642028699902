
import { defineComponent } from 'vue'
// import { useQuery } from '@urql/vue';
import { Manga, Chapter } from '@/types'

export default defineComponent({
  name: 'Archive',
  data() {
    return {
      data: {} as Chapter,
      manga: {} as Manga,
      loading: true,
      error: false as string | false,
      readingpage: 0,
      loadlimit: 0,
      loadedimages: [] as number[],
      firsttoload: 0,
      gui: true,
      readdir: localStorage.readdir || 'ltr'
    }
  },
  mounted() {
    this.getdata(() => {

      /** get current page from hash if exist */
      const hashpage = parseInt(location.hash.substring(1))
      if(hashpage) {
        this.readingpage = (hashpage>=1&&hashpage<=this.data.images.length ? hashpage : 1)-1
      } else if(location.hash.substring(1) == 'last') {
        this.readingpage = this.data.images.length-1
      }

      /** arrow keys to navigate */
      window.addEventListener('keydown',this.onKeyDown)
    })
  },
  beforeUnmount() {
    /** remove event listeners */
    window.removeEventListener('keydown', this.onKeyDown)
  },

  watch: {
    /** update hash on page change */
    'readingpage': {
      handler() {
        history.replaceState(history.state, '', '#'+(this.readingpage+1))
      }
    },

    /** update localstorage on reading direction change */
    'readdir': {
      handler() {
        localStorage.readdir = this.readdir
      }
    }
  },

  methods: {
    /* Fetch data from the api */
    async getdata(callback?: () => void) {
      this.readingpage = 0
      this.loadlimit = 0
      this.loading = true
      const data = {
        chapter: (await this.axios.get(`/api/chapter/${this.$route.params.manga}/${this.$route.params.id}.json`)).data
      }

      
      this.error = data.chapter===null ? 'Capitolo non trovato' : false
      if(!data.chapter) return

      this.data = data.chapter;
      for (let i = 0; i < data.chapter.images.length; i++) {
        this.loadedimages[i] = 0;
      }
      this.manga = data.chapter.manga

      /** onyl call if defined */
      callback && callback()
      this.loading = false
    },

    /** get chapter relative to the current one */
    getChapter(val: number) {
      var i = 0;
      var res = ''
      this.manga.chapters.forEach(el => {
        if(el.chapter == this.$route.params.id) {
          res = this.manga.chapters[i+val].chapter
        }
        i++
      })
      return res
    },

    /** go to the page to the right */
    readright() {
      if(this.readdir == 'rtl') {
        this.realprevious()
      } else {
        this.realnext()
      }
    },
    /** go to the page to the left */
    readleft() {
      if(this.readdir == 'rtl') {
        this.realnext()
      } else {
        this.realprevious()
      }
    },
    /** go to the next page or next chapter */
    realnext() {
      if(!this.data.webtoon) {
        if(this.readingpage != this.data.images.length-1) {
          this.readingpage++
        } else if(this.$route.params.id!=this.manga.chapters.slice().pop()?.chapter) {
          this.$router.push('/chapter/'+this.$route.params.manga+'/'+this.getChapter(1))
        } else {
          this.$router.push('/manga/'+this.$route.params.manga)
        }
      } else {
        if(this.$route.params.id!=this.manga.chapters.slice().pop()?.chapter) {
          this.$router.push('/chapter/'+this.$route.params.manga+'/'+this.getChapter(1))
        } else {
          this.$router.push('/manga/'+this.$route.params.manga)
        }
      }
    },
    /** go to the previous page or previous chapter */
    realprevious() {
      if(!this.data.webtoon) {
        if(this.readingpage != 0) {
          this.readingpage--
        } else if(this.$route.params.id!=this.manga.chapters[0].chapter) {
          this.$router.push('/chapter/'+this.$route.params.manga+'/'+this.getChapter(-1)+'#last')
        } else {
          this.$router.push('/manga/'+this.$route.params.manga)
        }
      } else {
        if(this.$route.params.id!=this.manga.chapters[0].chapter) {
          this.$router.push('/chapter/'+this.$route.params.manga+'/'+this.getChapter(-1)+'#last')
        } else {
          this.$router.push('/manga/'+this.$route.params.manga)
        }
      }
    },
    /** update the next image to be loadded */
    updatefirsttoload() {
      for(let i = this.readingpage; i < this.loadedimages.length; i++) {
        if(this.loadedimages[i] == 0) {
          this.firsttoload = i;
          return
        }
      }
    },
    /** load next image after one has loaded */
    afterimageload(page: string) {
      this.loadedimages[this.data.images.indexOf(page)] = 1;
      this.updatefirsttoload();
    },
    /** calculate witch side of the image you clicked */
    imageclick(e: any) { // eslint-disable-line
      const width = e.target.getBoundingClientRect().width
      if(e.pageX-(screen.width-width)/2 > width/3*2) {
        this.readright()
      } else if(e.pageX-(screen.width-width)/2 < width/3) {
        this.readleft()
      } else {
        this.gui = !this.gui
      }
    },

    /** arrow keys to navigate */
    onKeyDown(e: any) { // eslint-disable-line
      if (e.key == 'ArrowLeft') {
        this.readleft()
      } else if(e.key == 'ArrowRight') {
        this.readright()
      }
    }
  }
})
